import { TFunction } from 'i18next'
import * as Yup from 'yup'

import { BenefitIcons } from 'pages/product/update/components/featuresAndHighlights/benefits/types'
import { ProductType } from 'types/products/enums'
import { AppDTO, BenefitIconType, ProductCategoriesConfig } from 'types/products/product'
import { EMAIL_REGEX } from 'utils/strings'

//Essentials
const NAME_FIELD_MAX_CHARS = 256
const DESCRIPTION_FIELD_MAX_CHARS = 256
export const SHORT_DESCRIPTION_MAX_CHARS = 80
export const PREREQUISITE_FIELD_MAX_CHARS = 160

//Features & Highlights
export const BENEFITS_MAX_LIMIT = 6
export const BENEFIT_TITLE_MAX_CHARS = 35
export const BENEFIT_DESCRIPTION_MAX_CHARS = 400
export const FEATURES_MAX_LIMIT = 6
export const HIGHLIGHT_VALUE_MAX_CHARS = 25
export const HIGHLIGHT_TITLE_MAX_CHARS = 25
export const HIGHLIGHTS_MAX_LIMIT = 3
export const IMAGES_MAX_LIMIT = 10
export const TRAININGS_MAX_LIMIT = 10

//Marketing & Branding
export const CASE_STUDIES_MAX_LIMIT = 10
export const RELATED_PRODUCTS_MAX_LIMIT = 10

//Contacts
const CONTACTS_NAME_MAX_CHARS = 100
const CONTACTS_EMAIL_MAX_CHARS = 100

//Common
export const TEXT_BLOCKS_MAX_CHARS = 5000
export const FILE_MAX_SIZE = 15 * 1024 * 1024 //15 MB
export const IMAGE_MAX_SIZE = 5 * 1024 * 1024 //5 MB
export const URL_MAX_CHARS_LIMIT = 2048
export const VIDEO_NAME_MAX_CHARS_LIMIT = 50
export const VIDEO_DESCRIPTION_MAX_CHARS_LIMIT = 500
export const videoRegExp = new RegExp(/youtube\.com\/(watch\?v=|embed\/).+|youtu\.be\/.+|vimeo\.com\//gm)
export const directYoutubeRegExp = new RegExp(/youtube\.com\/watch\?v=.+/gm)

export const getUpdateProductFormValidationSchema = (
  t: TFunction<['products', 'common', 'errors']>,
  product: AppDTO,
  categoriesConfig: ProductCategoriesConfig,
) => {
  const richTextSchema = Yup.object()
    .shape({
      plain: Yup.string()
        .max(
          TEXT_BLOCKS_MAX_CHARS,
          t('errors|form_validation.chars_limit', {
            charsCount: TEXT_BLOCKS_MAX_CHARS,
          }),
        )
        .nullable(),
      formatted: Yup.object().nullable(),
    })
    .nullable()
    .optional()

  // We can not use Yup.string().email() as Core do not accept emails like test@domain
  const emailSchema = Yup.string().matches(EMAIL_REGEX, t('products|upsert.validation.email'))

  const attachmentMeta = Yup.object().shape({
    key: Yup.string(),
    name: Yup.string(),
    size: Yup.number(),
    id: Yup.string(),
    signed_url: Yup.string(),
  })

  const benefitsSchema = Yup.object().shape({
    icon: Yup.mixed<BenefitIconType>().oneOf(BenefitIcons),
    title: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        BENEFIT_TITLE_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: BENEFIT_TITLE_MAX_CHARS,
        }),
      ),
    description: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        BENEFIT_DESCRIPTION_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: BENEFIT_DESCRIPTION_MAX_CHARS,
        }),
      ),
  })

  const featureSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        NAME_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: NAME_FIELD_MAX_CHARS,
        }),
      )
      .optional(),
    description: Yup.object().shape({
      plain: Yup.string()
        .trim()
        .required(t('errors|form_validation.required'))
        .max(
          TEXT_BLOCKS_MAX_CHARS,
          t('errors|form_validation.chars_limit', {
            charsCount: TEXT_BLOCKS_MAX_CHARS,
          }),
        )
        .optional(),
      formatted: Yup.object(),
    }),
    attachment: Yup.array().of(attachmentMeta),
    id: Yup.string(),
    ordering: Yup.number().optional(),
  })

  const imageDraft = Yup.object().shape({
    descriptionPlainText: Yup.string().max(
      DESCRIPTION_FIELD_MAX_CHARS,
      t('errors|form_validation.chars_limit', {
        charsCount: DESCRIPTION_FIELD_MAX_CHARS,
      }),
    ),
    attachment: Yup.array()
      .of(attachmentMeta)
      .test({
        message: t('products|upsert.validation.image_required'),
        test: value => {
          //if value is undefined (it is a default value when side modal is closed) return true to pass form validation
          if (value === undefined) {
            return true
          }
          return !!value?.length && !!value[0]?.name
        },
      }),
    attachmentThumbnail: Yup.array().of(attachmentMeta),
    id: Yup.string(),
    ordering: Yup.number().optional(),
  })

  const urlSchema = Yup.string()
    .url(t('errors|form_validation.url'))
    .max(
      URL_MAX_CHARS_LIMIT,
      t('errors|form_validation.chars_limit', {
        charsCount: URL_MAX_CHARS_LIMIT,
      }),
    )

  const videoUrlSchema = urlSchema.matches(videoRegExp, t('products|upsert.validation.video_url'))
  const checkDraftVideoUrl = () =>
    Yup.string()
      .transform(value => (value === null ? undefined : value))
      .test({
        name: 'required',
        message: t('errors|form_validation.required'),
        test: value => {
          if (value === undefined) {
            return true
          }
          return !!value.length
        },
      })
      .url(t('errors|form_validation.url'))
      .max(
        URL_MAX_CHARS_LIMIT,
        t('errors|form_validation.chars_limit', {
          charsCount: URL_MAX_CHARS_LIMIT,
        }),
      )
      .matches(videoRegExp, t('products|upsert.validation.video_url'))

  const videoNameSchema = Yup.string().max(
    VIDEO_NAME_MAX_CHARS_LIMIT,
    t('errors|form_validation.chars_limit', {
      charsCount: VIDEO_NAME_MAX_CHARS_LIMIT,
    }),
  )

  const videoDescriptionSchema = Yup.string().max(
    VIDEO_DESCRIPTION_MAX_CHARS_LIMIT,
    t('errors|form_validation.chars_limit', {
      charsCount: VIDEO_DESCRIPTION_MAX_CHARS_LIMIT,
    }),
  )

  const videoMetaFieldSchema = Yup.object().shape({
    url: videoUrlSchema,
    description: videoDescriptionSchema,
  })

  const nameVideoMetaFieldSchema = Yup.object().shape({
    url: videoUrlSchema,
    name: videoNameSchema,
    description: videoDescriptionSchema,
  })

  const highlightSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        HIGHLIGHT_TITLE_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: HIGHLIGHT_TITLE_MAX_CHARS,
        }),
      ),
    value: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        HIGHLIGHT_VALUE_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: HIGHLIGHT_VALUE_MAX_CHARS,
        }),
      ),
  })

  const appFieldsValidation = Yup.object({
    name: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        NAME_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: NAME_FIELD_MAX_CHARS,
        }),
      ),
    shortDescription: Yup.string()
      .trim()
      .max(
        SHORT_DESCRIPTION_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: SHORT_DESCRIPTION_MAX_CHARS,
        }),
      ),
    prerequisites: Yup.object()
      .shape({
        plain: Yup.string()
          .max(
            PREREQUISITE_FIELD_MAX_CHARS,
            t('errors|form_validation.chars_limit', {
              charsCount: PREREQUISITE_FIELD_MAX_CHARS,
            }),
          )
          .nullable(),
        formatted: Yup.object().nullable(),
      })
      .nullable()
      .optional(),
    applicationCategory: Yup.object().shape({
      category: Yup.string(),
      subcategory: Yup.string()
        .test({
          name: 'subcategory is required if a specific category is selected',
          message: t('errors|form_validation.required'),
          test: function (value, context) {
            const category = context.parent.category
            const hasSubcategory = !!categoriesConfig[category].subcategories?.length
            const isRequired = !!category && hasSubcategory && !value

            return !isRequired
          },
        })
        .nullable(),
    }),
    categoryLogoUrl: Yup.string().required(t('errors|form_validation.required')),
    commercialModelType: Yup.string().nullable(),
    commercialModelDescription: richTextSchema,
    regionsMarketsTree: Yup.array().of(
      Yup.object().shape({
        selected: Yup.boolean(),
        indeterminate: Yup.boolean(),
        children: Yup.array().of(Yup.object().shape({ selected: Yup.boolean() })),
      }),
    ),
    video: Yup.array().of(videoMetaFieldSchema),
    fullDescription: richTextSchema,
    draftFeature: featureSchema.default(null).nullable(),
    draftImage: imageDraft.default(null).nullable(),
    draftBenefit: benefitsSchema.default(null).nullable(),
    benefits: Yup.array()
      .of(benefitsSchema)
      .max(
        BENEFITS_MAX_LIMIT,
        t('errors|form_validation.chars_limit', {
          charsCount: BENEFITS_MAX_LIMIT,
        }),
      ),
    draftHighlights: Yup.array().of(highlightSchema),
    highlights: Yup.array().of(highlightSchema),
    draftVideoMeta: Yup.object().shape({
      url: checkDraftVideoUrl(),
      description: videoDescriptionSchema,
    }),
    draftNamedVideoMeta: Yup.object({
      url: checkDraftVideoUrl(),
      name: videoNameSchema
        .transform(value => (value === null ? undefined : value))
        .test({
          name: 'required',
          message: t('errors|form_validation.required'),
          test: value => {
            if (value === undefined) {
              return true
            }
            return !!value.length
          },
        }),
      description: videoDescriptionSchema,
    }),
    relatedProducts: Yup.array().max(
      RELATED_PRODUCTS_MAX_LIMIT,
      t('products|upsert.validation.max_related_products', {
        maxRelatedProducts: RELATED_PRODUCTS_MAX_LIMIT,
      }),
    ),
    ownerName: Yup.string()
      .trim()
      .max(
        CONTACTS_NAME_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: CONTACTS_NAME_MAX_CHARS,
        }),
      ),
    ownerEmail: emailSchema.trim().max(
      CONTACTS_EMAIL_MAX_CHARS,
      t('errors|form_validation.chars_limit', {
        charsCount: CONTACTS_EMAIL_MAX_CHARS,
      }),
    ),
    supportName: Yup.string()
      .trim()
      .max(
        CONTACTS_NAME_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: CONTACTS_NAME_MAX_CHARS,
        }),
      ),
    supportEmail: emailSchema.trim().max(
      CONTACTS_EMAIL_MAX_CHARS,
      t('errors|form_validation.chars_limit', {
        charsCount: CONTACTS_EMAIL_MAX_CHARS,
      }),
    ),
  })

  // validation for fields which are specific for No-Code Apps
  const noCodeAppFieldsValidation = Yup.object({})

  // validation for fields which are specific for Native Apps
  const nativeAppFieldsValidation = Yup.object({
    pricing: Yup.array().of(attachmentMeta),
    infographic: Yup.array().of(attachmentMeta),
    pitchDeck: Yup.array().of(attachmentMeta),
    caseStudies: Yup.array().of(attachmentMeta),
    features: Yup.array().of(featureSchema),
    developmentRoadMap: Yup.array().of(attachmentMeta),
    productTour: Yup.array().of(nameVideoMetaFieldSchema),
    trainings: Yup.array().of(nameVideoMetaFieldSchema),
    knowledgeBase: Yup.object().shape({
      attachment: Yup.array().of(attachmentMeta),
      url: urlSchema,
    }),
    glossary: Yup.object().shape({
      attachment: Yup.array().of(attachmentMeta),
      description: richTextSchema,
    }),
  })

  if (product.productType === ProductType.NO_CODE_APPLICATION) {
    return Yup.object({
      ...appFieldsValidation.fields,
      ...noCodeAppFieldsValidation.fields,
    })
  }

  //native app validation
  return Yup.object({
    ...appFieldsValidation.fields,
    ...nativeAppFieldsValidation.fields,
  })
}
